.text-image-panel {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &__image-wrapper {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
    @media(min-width: @screen-sm-min) {
      flex: 0 0 auto;
      width: 40%;
    }
    img {
      width: 100%;
      display: block;
      height: auto;
    }
  }
  &__text-wrapper {
    padding: 20px;
    flex: 1;
  }
  &[data-image-behind="true"] {
    text-align: center;
    .image-wrapper {
      width: 100%;
    }
    .text-wrapper {
      position: absolute;
      left: 0;
      bottom: 3%;
      width: 100%;
      @media (min-width: @screen-sm-min) {
        bottom: auto;
        top: 50%;
        left:50%;
        width: auto;
        transform: translate(-50%, -50%);
      }
    }
  }
}
