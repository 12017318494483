.push-notification {
  display: none;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 960px;
  z-index: 900;
  background-color: white;
  border: 1px solid #ccc;
  border-top-width: 0;
  box-shadow: 0px 5px 20px 1px rgba(0,0,0,0.39);
  padding: 10px 20px;

  @media (min-width: @screen-lg-min) {
    max-width: 1260px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media (min-width: @screen-md-min) {
      flex-direction: row;
    }
  }

  &__content {
    text-align: center;

    @media (min-width: @screen-md-min) {
      text-align: left;
    }
  }

  &__buttons {
    display: flex;
  }

  &__button {
    cursor: pointer;
    min-width: 90px;
    margin: 0;
    border: none;
    padding: 12px;
    font-size: 13px;
    line-height: 13px;
    text-align: center;

    &--primary {
      background: #00afed;
      color: #fff;
    }

    &--secondary {
      background: #fff;
      color: #00afed;
      border: 1px solid #eee;
      margin: 0 10px 0 0;
    }
  }
}