.account-page {
  padding: 0 10px;
  &__body {
    font-size: @font-size-large;
    margin-bottom: 10px;
  }
  &__alert {
    font-size: @font-size-base;
    margin-bottom: 10px;
    color: @lovell-orange;
  }
  &__welcome-text {
    padding-top: 25px;
    text-align: center;
    font-size: @footer-font-size-medium;
    line-height: 22px;
    h2 {
      font-weight: bold;
      margin-bottom: 5px;
      margin-top: 0;
    }
    h3 {
      font-weight: bold;
      margin-top: 0;
    }
    div {
      opacity: 0;
      height: 0;
      margin: 0;
      text-align: center;
    }
    // Margins for text are affected as screen size increases
    @media (min-width: @screen-sm-min)  {
      margin-left: 10%;
      margin-right: 10%;
    }
    @media (min-width: @screen-md-min)  {
      margin-left: 0;
      margin-right: 0;
      display: block;
    }
  }
  &__section-wrapper {
    margin-top: 20px;
    padding: 0;
    @media (min-width: @screen-md-min) {
      .flex-container(wrap, flex-start, flex-start);
      width: 100%;
    }
  }
  &__section {
    border-bottom: 1px solid @lovell-border-color;
    &:first-child {
      border-top: 1px solid @lovell-border-color;
      @media(min-width: @screen-md-min) {
        border-top: none;
      }
    }
    .minus {
      display: none;
    }
    &.is-expanded {
      .plus {
        display: none;
      }
      .minus {
        display: block;
      }
      .account-page__section-header {
        border-bottom: 1px solid @lovell-border-color;
      }
    }
    @media (min-width: @screen-md-min) {
      border-bottom: none;
      padding: 15px 90px 15px 15px;
      .flex-item(0,0,48%,flex-start);
      width: 48%;
      position: relative;
      cursor: pointer;
      .account-page__section-header {
        display: block;
      }
      &:after {
        content: ' ';
        background: @icon-arrow-black;
        width: 16px;
        display: block;
        height: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        right: 60px;
        top: 50%;
        transform: translateY(-50%);
      }
      &.is-expanded {
        padding: 0;
        cursor: default;
        .flex-item(1,1,auto, stretch);
        width: 100%;
        .account-page__section-header {
          display: none;
        }
        &:after {
          content: none;
        }
        .plus {
          display: none;
        }
      }
    }
  }
  &__section-header {
    padding: 15px 0;
    cursor: pointer;
    @media(min-width: @screen-md-min) {
      padding: 0;
    }
  }
  &__section-content {
    padding: 40px 0;
    display: none;
    &--history {
      padding: 20px 0;
    }
    .display {
      h3 {
        margin-top: 0;
      }
    }
    font-size: @font-size-base;
    @media (min-width: @screen-sm-min)  {
      @media (max-width: @screen-sm-max)  {
        padding-left: 15%;
        padding-right: 15%;
      }
    }
    @media (min-width: @screen-md-min)  {
      padding-left:0px;
    }
    .section {
      position: relative;
    }
    h3 {
      font-size: @footer-font-size-large;
    }

    // Clickable action
    .edit, .delete, .back, .add {
      display: inline-block;
      clear: both;
      width: auto;
      text-decoration: underline;
      color: @lovell-blue;
      margin-top: 10px;
      font-size: 16px;
      cursor: pointer;

    }
    // Forms for data are hidden until the user clicks edit
    form {
      display: none;
      .field-name {
        font-weight: lighter;
      }
    }
    @media (min-width: @screen-sm-min)  {
      // This is needed to ensure the correct height of
      // the card details section
      .spacer {
        width: 100%;
        display: inline-block;
      }
    }
    .account-details-display {
      .field {
        padding-left: 0;
        padding-right: 0;
      }
    }

    #password-display {
      .field {
        padding-left: 0;
        padding-right: 0;
      }
    }
    #preferences-form {
      display: block;
    }
    #newsletter-form {
      display: block;
      .subscription-text {
        margin-bottom: 20px;
        @media (max-width: @screen-xs-max)  {
          font-size: 12pt;
        }
        @media (min-width: @screen-sm-min)  {
          @media (max-width: @screen-sm-max)  {
            padding-right: 15%;
          }
        }
      }
      .checkbox-image {
        width: 39px;
        height: 39px;
        margin-bottom: 3px;
      }
    }
    #password-form {
      input {
        margin-top: 5px;
      }
    }
    .full-address-link {
      margin-bottom: 20px;
    }
    .postcode-search-link {
      margin-top: 10px;
      margin-bottom: 10px;
      @media (min-width: @screen-sm-min)  {
        padding-left: 10%;
        padding-right: 10%;
      }
    }
    .full-address-link, .postcode-search-link {
      cursor: pointer;
      display: block;
      text-align: right;
    }

    // Update button at bottom of form
    .update, .find-address {
      text-align: right;
      &#update-subscriptions {
        @media (min-width: @screen-md-min)  {
          text-align: left;
          margin-top: 20px;
          margin-bottom: 40px;
        }
      }
      &.find-address {
        @media (min-width: @screen-sm-min)  {
          width: 80%;
        }
        @media (min-width: @screen-md-min)  {
          width: 54%;
          .postcodeSearchResults {
            margin-bottom: 25px;
          }
        }
        @media (min-width: @screen-lg-min)  {
          width: 48%;
        }
      }
      button, .find-address-button {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        height: auto;
        &.save-new {
          // Save button only visible for new addresses
          display: none;
        }
        background-color: @lovell-blue;
        border: 0;
        color: white;
        font-size: 14px;
        letter-spacing: 1px;
        padding: 12px;
        border: 1px solid @lovell-blue;
        &.find-address-button {
          width: 100%;
          margin-bottom: 20px;
          @media (min-width: @screen-md-min)  {
            height: 45px;
          }
        }
        &:focus {
          border: 1px solid @lovell-gray;
        }
        @media (min-width: @screen-sm-min)  {
          width: 35%;
        }
        @media (min-width: @screen-md-min)  {
          width: 160px;
          height: 40px;
          margin-right: 46%;
          padding: 6px;
          margin-top: 0;
          margin-bottom: 0;
        }
        @media (min-width: @screen-lg-min)  {
          margin-right: 52%;
        }
      }
      .disabled {
        background-color:@lovell-gray;
      }
    }
    .find-address {
      margin-top:20px;
      @media (min-width: @screen-sm-min)  {
        margin-left: 10%;
        margin-right: 10%;
      }
      @media (min-width: @screen-md-min)  {
        margin-left: 10%;
        margin-right: 10%;
      }
    }
    .address-display {
      position: relative;
      line-height: 22px;
      @media (min-width: @screen-md-min)  {
        h3 {
          margin-bottom: 40px;
        }
      }
      #add-address {
        @media (min-width: @screen-sm-min)  {
          width: 100%;
          text-align: right;
          padding-right: 17%;
        }
        @media (min-width: @screen-md-min)  {
          text-align: left;
        }
      }
      .alt-address {
        position: relative;
        margin-bottom: 40px;
      }
      #add-delivery-address-new {
        display: block;
        clear: both;
        @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
          text-align: left;
          margin-right: 15%;
        }
      }
      .address-ref {
        background-color: #f2f2f2;
        padding: 10px;
        padding-left: 10px;
        margin-bottom: 15px;
        h3 {
          margin: 0;
        }
      }
    }
   }
  form {
    .field {
      width: 90%;
    }
  }
  &__password {
    h3 {
      margin-top: 40px !important;
    }
  }
  &__saved-addresses {
    margin-top: 20px !important;
  }
  &__panel-header {
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img.plus, img.minus {
      width: 22px;
    }
  }
  &__panel-header-description {
    font-size: 16px;
    line-height: 17px;
    padding-right: 40px;
  }
  &__panel-title {
    .main-font();
  }
  &__log-out {
    color: black;
    background-color: white;
    padding: 12px;
    border: 1px solid black;
    font-size: 14px;
    margin-top: 25px;
    margin-bottom: 25px;
    width: 100%;
    display: block;
    text-decoration: none;
    text-align: center;
    @media (min-width: @screen-sm-min)  {
      width: 60%;
      margin-left: 20%;
      margin-right: 20%;
    }
    @media (min-width: @screen-md-min)  {
      width: 30%;
      margin-left: 35%;
      margin-right: 35%;
      padding: 5px;
    }
  }
  &__history-item {
    .flex-container(nowrap,flex-start,space-between);
    padding: 16px 10px;
    @media (min-width: @screen-md-min) {
      padding: 8px 10px;
    }
    width: 100%;
    clear: both;
    &--even {
      background-color: lighten(@lovell-light-gray, 10%);
    }
    &.active {
      background: @active-highlight;
    }
  }
  &__history-item-field {
    font-size: @font-size-base;
    .flex-item(0,0,30%);
    @media (min-width: @screen-sm-min) {
      .flex-item(0,0,20%);
    }
    &.tradeAccount {
      .flex-item(0,0,16%);
    }
    text-align: left;
  }
  &__icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    &--close {
      display: none;
    }
  }
  &__vouchers {
    display: table;
    margin-bottom: 0;
    .table-header {
      .main-font-bold();
      border-bottom: 1px solid @lovell-border-color;
    }
    .table-row {
      .flex-container(nowrap, center, space-between);
      float: none;
      clear: both;
      padding: 10px 0;
      border-bottom: 1px solid @lovell-border-color;
      &:last-child {
        border-bottom: none;
      }
    }
    .table-cell1 {
      .flex-item(0,0,25%);
      &:first-child {
        .flex-item(0,0,50%);
      }
      @media(min-width: @screen-sm-min) {
        .flex-item(0,0,22%);
        &:first-child {
          .flex-item(0,0,34%);
        }
      }
    }
    .expiry {
      display: block;
      font-size: @font-size-small;
    }
  }
  &__club {
    #cashout-form {
      display: block;
    }
  }
  &__section-newsletters {
    padding: 0;
  }
}

/**
*
* modifiers
*
**/
.account-page {
  &.is-closed {
    .welcome-text div {
      opacity: 1;
      height: auto;
    }
  }

}

// back to links
.back-to-home, .back-to-display, .back-to-orders {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  display: none;
  margin-top: 50px;
  margin-bottom: 20px;
  .main-font-bold();
  font-size: @font-size-large;
  // Left chevron icon
  @media(min-width: @screen-md-min) {
    display: block;
  }
  img {
    width: 8px;
    height: 14px;
    -ms-transform: rotate(180deg); /* IE 9 */
    -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
    transform: rotate(180deg);
    margin-right: 7px;
    margin-bottom: 3px;
    display: inline-block;
    &.blue {
      display: none;
    }
  }
  &:hover {
    color: #fff;
    img {
      display: none;
      &.blue {
        display: inline-block;
      }
    }
  }
}
.back-to-orders {
  display: flex !important;
  justify-content: flex-start;
  ion-icon {
    margin-right: 10px;
  }
}
