// signup email *********************************************************************************
.newsletter-signup-form {
  margin: 0 auto;
}
.newsletter-signup-form .inputs-wrapper {
  display: grid;
  grid-template-columns: 1fr auto;
}

.newsletter-signup-form .agree {
  margin-top: 20px;
}

#signup-form, .newsletter-signup-form {
  text-align: left;
  box-sizing: border-box;
  .form {
    width: 100%;
  }
  #email, #teacher-signup-email {
    padding: 10px;
    border: 1px solid #b3b3b3;
    border-right: none;
    color: #333;
    box-shadow: none !important;
    -webkit-appearance: none;
  }
  #signup-submit {
    border: none;
    border-radius: 0;
    padding: 10px 20px;
    font-size: 16px;
    @media (min-width: @screen-sm-min) {
      font-size: 14px;
    }
  }
}

#membership {
  text-align: center;
  margin-bottom: 20px;
  .signup-message {
    font-size: @footer-font-size-small;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    .main-font-bold();
    &.lsp-signup-message {
      font-size: 16px;
      @media (min-width: @screen-md-min) {
        margin-bottom: 15px;
        font-size: 18px;
      }
    }
    
  } 
  h3 {
    margin-bottom: 5px;
    font-size: @footer-font-size-medium;
    text-transform: none;
  }
  h4 {
    margin-top: 0;
    font-size: @footer-font-size-small;
  }
  // This is the box which slides in to replace the form
  // after a valid email address is submitted
  #newsSignupFeedback {
    // background-color: @lovell-light-gray;
    font-size: 14px;
    width: 100%;
    margin: 20px auto 20px;
    padding: 0;
  }
  // If an invalid email address is submitted,
  // this warning box will slide in
  #newsSignupFeedback.error {
    background-color: @lovell-warning;
  }


  @media (min-width: @screen-md-min) {
    // Font sizes increase as screen width increases
    h3 {
      font-size: 13pt;
      margin-top: 30px;
      margin-bottom: 20px;
    }
    h4 {
      font-size: @footer-font-size-medium;
    }
  }
}

#newsletterModal {
  .modal-dialog {
    @media(min-width: 1000px) {
      width: 90vw;
      margin: 50px 5vw;
    }
    @media(min-width: 1500px) {
      width: 1400px;
      margin: 50px auto;
    }
  }
  .modal-header {
    border-bottom: none;
    padding: 0;
    min-height: 0;
    button {
      z-index: 5;
      border: none;
      background-size: 30px 30px;
      box-shadow: 0 0 5px rgba(0,0,0,0.5);
    }
  }
  .modal-content {
    padding-bottom: 0;
  }
  h4.modal-title {
    text-align: center;
    padding: 10px;
    font-size: 26px;
    @media(min-width: 860px) {
      text-align: left;
      padding: 20px 0;
      font-size: 36px;
    }
  }
  .content-wrapper {
    @media(min-width: 860px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .image {
      @media(min-width: 860px) {
        padding: 20px;
        img {
          object-fit: cover;
          object-position: center;
          width: 100%;
          height: 100%;
        }
      }
    }
    .content {
      padding: 20px;
      @media(min-width: 860px) {
        display: flex;
        flex-direction: column;
      }
      p {
        text-align: center;
        margin: 5px 0 20px;
        padding: 0;
        .main-font();
        font-size: 18px;
        @media(min-width: 860px) {
          text-align: left;
        }
      }
    }
  }
  .button-wrapper {
    margin: 0;
  }
  .newsletter-agree {
    flex: 1;
    align-items: flex-end;
  }
  .newsletter-signup__select.lovell-form {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;
    padding: 20px 0px;
    @media(min-width: @screen-sm-min) {
      grid-template-columns: 1fr 1fr;
      
    }
    .field {
      margin: 0;
    }
  }
  .lovell-checkbox input:checked+span:before, .lovell-checkbox input+span:before {
    border: 2px solid #333;
    margin-right: 10px;
  }

  #newsSignupFeedback {
    .alert {
      background-color: transparent;
      color: @lovell-green;
      border: none;
      padding: 0;
      font-size: 15px;
      margin: 0 0 20px 0;
      .main-font-bold();
      text-align: center;
    }
  }
}