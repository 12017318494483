.order-form-page {
  &__panels {
    //.flex-container(nowrap, flex-start, flex-start, column);
    @media(min-width: @screen-sm-min) {
      .flex-container(wrap, flex-start, space-between, row-reverse);
    }
    padding: 20px;
  }
  &__panel {
    &--text {
      p {
        margin-top: 0;
      }
      @media(min-width: @screen-sm-min) {
        .flex-item(1,1,60%);
      }
    }
    &--form {
      @media(min-width: @screen-sm-min) {
        .flex-item(0,0,40%,stretch);
        padding-left: 20px;
      }
      .button {
        width: 100%;
        padding: 12px;
      }
      .field-checkbox {
        font-size: @font-size-small;
      }
      .validation-error {
        padding: 0;
      }
    }
  }
  &__message {
    color: @lovell-orange;
    text-align: center;
    padding: 20px;
    background: @lovell-light-gray;
    border: 1px solid @lovell-border-color;
    margin-bottom: 30px;
    .flex-item(0,0,100%,stretch);
    h3 {
      margin-top: 0;
    }
  }
  &--order-form {
    width: 100%;
  }
  &--product {
    margin: 20px 0px;
    padding: 10px 2%;
    border: 1px solid #b3b3b3;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  &--product-image {
    height: 250px;
  }
  &--product-name {
    flex: 1 0 auto;
    font-size: 16px;
    text-align: center;
  }
  &--size-select {
    flex: 0 0 150px;
  }
  &--order-placed {
    font-size: 20px;
  }
  .field {
    flex-wrap: wrap !important;
  }
}
