.modal {
  .modal-dialog {
    width: 90vw;
    margin: 50px 5vw;
    box-shadow: 0 0 20px rgba(0,0,0,0.2);
    @media(min-width: @screen-md-min) {
      width: 60vw;
      margin: 50px 20vw;
    }
    @media(min-width: @screen-lg-min) {
      width: 40vw;
      margin: 50px 30vw;
    }
    .modal-content {
      border-radius: 0;
      padding-bottom: 15px;
      .modal-header {
        text-align: center;
        h3 {
          margin-top: 15px;
          margin-bottom: 0;
          @media (max-width: @screen-xs-max)  {
            font-size: 20px;
          }
        }
        h4 {
          font-size: 14pt;
          font-weight: bold;
          margin-bottom: 0px;
        }
        h5 {
          font-size: 12pt;
          font-weight: bold;
          margin-top: 3px;
          margin-bottom: 0;
        }
        font-weight: bold;
        button.close {
          width: 40px;
          height: 40px;
          background: url(../../responsive-images/close-icon.svg) no-repeat 0 0;
          background: url(../../images/icons/close-outline.svg) no-repeat 0 0;
          background-size: 20px 20px;
          background-position: 50% 50%;
          background-color: white;
          border: 1px solid #ccc;
          font-size: 0;
          border-radius: 20px;
          position: absolute;
          margin: 0;
          top: -10px;
          right: -10px;
        }
      }
      .modal-logo {
        margin-bottom: 5px;
        width: 100%;
        .flex-container(nowrap,center, center);
        img {
          margin: 0 auto;
          display: inline-block;
          width: auto;
          display: block;
          height: auto;
          margin: 0 auto;
          max-width: 100%;
          max-height: 40px;
        }
      }
      .modal-body {
        padding: 0;
        .body-content {
          padding: 15px;
          h4 {
            margin-top: 0;
            margin-bottom: 0px;
          }
          p {
            margin-top: 0;
          }
        }
        .img-responsive {
          width: 100%;
        }
        ul {
          padding: 20px;
          line-height: 20px;
          font-size: 16px;
          padding-bottom: 0;
          li {
            margin-bottom: 5px;
            margin-left: 15px;
          }
        }
        .confirm-row {
          margin-bottom: 10px;
          text-align: center;
        }
        .confirm-label {
          display: inline-block;
          vertical-align: middle;
          text-align: right;
          margin-right: 10px;
          width: 100px;
        }
        .confirm-field {
          background: #eeeeee;
          padding: 8px 15px;
          text-transform: uppercase;
          border: 1px solid #ccc;
          min-width: 120px;
          display: inline-block;
          vertical-align: middle;
          font-weight: bold;
          img {
            margin-top: 5px;
            width: 85px;
          }
        }
        h4 {
          font-size: 17px;
        }
        @media (min-width: @screen-sm-min)  {
          p, ul {
            padding-left: 75px;
            padding-right: 75px;
          }
          h4 {
            font-size: 18px;
            padding-left: 75px;
            padding-right: 75px;
          }
        }
      }
      .modal-footer {
        border-top: 0;
        padding-top: 0;
        @media (min-width: @screen-sm-min)  {
          padding-left: 75px;
          padding-right: 75px;
        }
        .modal-btn {
          display: block;
          width: 100%;
          padding: 10px;
          text-align: center;
          text-decoration: none;
          font-size: 13pt;
          &.confirm-prsnl {
            background: #1b8a0d;
            color: white;
            margin-bottom: 10px;
          }
          &.cancel-prsnl {
            background: white;
            color: black;
            border: 1px solid black;
          }
          img {
            margin-left: 10px;
            width: 8px;
            height: 14px;
          }
          .fa {
            margin-left: 20px;
          }
        }
      }
    }
  }
}

body.modal-open {
  overflow: hidden !important;
}

#modal-news-signup {
  align-items: center;
  justify-content: center;
  &.in {
    display: flex !important;
  }
  .modal-dialog {
    width: auto !important;
    height: auto;
    max-width: 90vw;
    max-height: 90vh;
    overflow: hidden;
    margin: 0 !important;
    
    .modal-content {
      display: flex;
      flex-direction: column;
      padding-bottom: 0;
      @media(min-width: @screen-sm-min) {
        padding-bottom: 20px;
      }
      .modal-header {
        padding: 0;
        overflow: hidden;
        max-height: 30vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img {
          display: block;
          width: 100%;
          height: auto;
        }
        button {
          &.close {
            background: none;
            border: none;
            width: 22px;
            height: 22px;
            top: 10px;
            right: 10px;
            padding: 0;
            .feather {
              color: #fff;
              font-weight: bold;
            }
          }
        }
        &.newsletter-signup-response {
          max-height: none;
          padding: 20px;
          button.close {
            svg.feather {
              color: #333;
            }
          }
          h1 {
            font-size: 26px;
            color: #333;
          }
          h2 {
            font-size: 20px;
            color: #666;
          }
          p {
            color: #333;
          }
        }
      }
      .modal-body {
        padding: 20px 20px 0;
        @media(min-width: @screen-sm-min) {
          padding: 40px 40px 0;
        }
        .field {
          margin-bottom: 10px;
          .field-control {
            input, .sod_label {
              border-top: none;
              border-left: none;
              border-right: none;
              padding: 10px 0px 0;
              .main-font-bold();
              font-size: 16px;
              height: auto;
            }
            label {
              left: 0px;
              padding: 0;
              font-size: 12px;
            }
            .sod_select {
              .sod_label {
                font-size: 12px;
                .main-font();
              }
              label {
                display: none;
              }
              &.is-valid {
                label {
                  display: block;
                  font-size: 9px;
                  top: 2px;
                }
                .sod_label {
                  .main-font-bold();
                  font-size: 16px;
                }
              }
            }
            input:focus+label {
              font-size: 9px;
              top: 5px;
            }
            input:not(:placeholder-shown)+label {
              font-size: 9px;
              top: 5px;
            }
            &.is-valid {
              label {
                font-size: 9px;
              }
            }
          }
          .sod_select .sod_label {
            line-height: 24px;
            &:after {
              line-height: normal;
            }
          }
          &--newsletter-agree-terms {
            margin: 20px 0;
            label {
              span {
                color: #333;
                font-size: 10px;
                line-height: 12px;
              }
            }
          }
        }
        button {
          .main-font-bold();
          margin-bottom: 10px;
        }
      }
      .modal-footer {
        padding: 0px 40px 10px;
      }
      .terms {
        font-size: 12px;
        color: #333;
      }
      
    }
  }
}

.modal-backdrop.in {
  background-color: #000;
  opacity: 0.7 !important;
}