.lovell-button {
  position: relative;
  margin-bottom: 20px;

  &.continue {
    width: 200px;
    margin-top: 20px;
  }

  .loader {
    position: absolute;
    display: block;
    margin-left: 20px;
    font-size: 4px;
    width: 6px;
    height: 6px;
    top: 50%;
    left: 70%;
  }
}

.input-text {
  margin-bottom: 20px;
  border: 1px solid red;
  box-shadow: none;
}

.checkout-title {
  display: flex;
  align-items: center;
  justify-content: center;
  ion-icon {
    background: #333;
    color: #fff;
    border-radius: 50%;
    padding: 10px;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.checkout-page {
  margin-bottom: 20px;

  @media (min-width: @screen-sm-min) {
    // main contentdoesn't occupy full width on tablet
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: @screen-md-min) {
    padding-top: 30px;
    .flex-container(nowrap, flex-start);

    .panel-register {
      .flex-item(1, 1, 50%, stretch);
      vertical-align: top;
      // border-right: 1px solid #b3b3b3;
      padding-right: 40px;

      input.input-text {
        margin-bottom: 20px;
      }
    }

    .panel-login {
      .flex-item(1, 1, 50%, stretch);
      padding-left: 40px;
      vertical-align: top;

      input.input-text {
        margin-bottom: 20px;
      }
    }
  }

  .panel {
    background-color: #fff;
    padding: 60px 20px 40px;
    display: none;
    min-height: 400px;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    &.active {
      display: flex;
    }
    .panel-title {
      margin: 0 0 50px;
      justify-self: flex-start;
      display: none;
    }
    form {
      width: 100%;
      max-width: 400px;
    }
    #btn-guest-checkout {
      background-color: transparent;
      color: #333 !important;
      border: 1px solid #333 !important;
    }
    @media (min-width: @screen-md-min) {
      display: flex;
      margin: 0 10px;
      padding: 40px 20px;
      .panel-title {
        display: block;
      }
    }
  }

  .login-tabs {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    .login-tab {
      flex: 0 0 50%;
      background-color: #fff;
      text-align: center;
      padding: 20px;
      border-bottom: 5px solid #ccc;
      font-weight: bold;
      &.active {
        border-color: #333;
      }
    }
    @media (min-width: @screen-md-min) {
      display: none;
    }
  }
}

#login-password,
#login-email,
#register-email {
  color: black;
  margin-bottom: 20px;
}

.forgotten-password {
  // font-size: 12pt;
  text-align: center;
  display: block;
}

.registration-message {
  text-align: center;
  h2 {
    margin-bottom: 0px;
  }
  h4 {
    margin-top: 0px;
  }
}

#btn-register {
  max-width: 400px;
}

.registration-form {
  display: none;
  margin-bottom: 20px;

  &.open {
    display: block;
  }
}

.payment-logos {
  // payment brand logos at bottom of checkout pages
  text-align: center;
  color: #999;
  margin: 75px 0 30px;

  img {
    display: block;
    margin: 0 auto;
  }
}

.checkout-panel {
  .payment-graphic {
    margin: 0;

    img.payment-logos-checkout {
      max-width: 100%;
      height: auto;
      width: auto;
      max-height: 50px;
      margin: 0 auto;
    }
  }

  .button-wrapper {
    margin: 0;
  }
}

// styles for the checkout baskey display to the right of checkout forms
// checkout-side-basket only visible on desktop/wide
#checkout-side-basket {
  @media (min-width: @screen-md-min) {
    width: 420px;

    .checkout-side-basket-inner {
      padding-left: 60px !important;
      .preorder-available {
        display: none;
      }
    }

    #basket-tables-inner {
      padding-right: 0;
    }
  }

  .basket-footer-items {
    float: none;
    width: 100%;
    clear: both;

    .basket-footer-items-inner {
      padding-left: 0;
    }
  }

  #updateBasketForm {
    //hide on checkout
    display: none;
  }

  .stock-message-wrapper {
    display: none;
  }

  .mciMessage {
    display: none;
  }

  .stock-warning {
    display: none;
  }

  .basket-remove-item {
    display: none;
  }

  .baskettables {
    overflow-y: hidden;
    overflow-x: hidden;
    padding-right: 0px;
    border-right: none !important;
    width: 100%;
    border-top: none;
    padding-bottom: 20px;

    .basketTable {
      overflow: hidden;
      position: relative;
      clear: none;

      .basket-item {
        border-bottom: 1px solid @lovell-border-color;
        clear: both;
        padding-bottom: 0;
        margin-bottom: 20px;
        padding-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: none;
        }

        .basket-image {
          width: 25%;
          .flex-item(0, 0, 25%);
        }

        .basket-data {
          .basket-colour, .basket-fixed-carriage {
            font-size: 10pt;
          }
        }

        .basket-item-color {
          float: left;
          margin-right: 20px;
        }

        .basket-item-title {
          font-size: 11pt;
          margin-bottom: 10px;
        }

        .basket-size-qty {
          padding-top: 0;

          .basket-size {
            font-size: 11pt;
          }

          .basket-quantity {
            font-size: 11pt;
          }
        }

        .basket-item-color,
        .basket-item-qty,
        .basket-item-price {
          font-size: 12pt;
        }

        .basket-item-cost {
          display: none;
        }

        .offer {
          color: #e86107;
          display: none;
        }

        .basket-remove-item a {
          text-decoration: none;
        }

        .basket-tax-alert {
          display: none;
        }

        .basket-price {
          margin-bottom: 0;
          margin-top: 5px;

          strong {
            font-size: 0;
          }

          .original {
            display: inline-block;
            font-size: 9pt;
            margin-right: 20px;
          }

          .now {
            display: inline-block;
            font-size: 12pt;
          }
        }
      }

      .basket-table-summary {
        margin: 0;
        padding: 0px;
        float: none;
        width: 100%;
      }

      .basket-psnl-message {
        display: none;
        padding: 10px 0;
        width: 100%;
        text-align: left;
        font-size: 12pt;

        // clock icon
        .fa {
          display: table-cell;
          vertical-align: middle;
        }
      }

      .basket-expected {
        margin: 10px 0 0;
        padding: 0;
      }
    }
  }

  .basket-footer-items {
    padding-left: 0;
    border-left: none !important;
    margin-top: 0px;

    .basket-footer-item {
      font-weight: normal;
    }

    .basket-summary {
      margin-bottom: 15px;
    }
  }

  .delivery-options {
    display: none;
  }

  .basket-column--1 {
    display: none;
  }

  .basket-column--2 {
    flex-basis: 100%;
    width: 100%;
  }

  h1.basket-title {
    background: none;
    text-align: left;
    font-size: 16pt;
    padding: 0px;
    border-bottom: 1px solid #b3b3b3;
    margin-top: 20px;
  }

  h2.basket-item-title {
    font-size: 13pt;
    margin-bottom: 15px;
  }

  h4.basket-header {
    display: none;
  }

  .product-code {
    display: none;
  }
}


// readonly inputs
.readonly {
  background: #f2f2f2;
  color: #999 !important;
}

// checkout finished - confirmation page
.checkout-complete-header {
  text-align: center;
  font-size: 18pt;
  font-weight: bold;
}

.basketConfirmation {
  &.text-center {
    text-align: center;

    h4 {
      text-align: center;
    }

    a {
      text-align: center;
    }
  }

  margin-bottom: 30px;

  a {
    font-size: 14pt;
  }
}

#searchResults_add {
  margin-bottom: 10px;
}

.social {
  margin: 20px 0;
}

.continue-shopping {
  h3 {
    text-align: center;
  }
}

.guest-save-password {
  margin-top: 30px;
  text-align: center;

  h3 {
    margin-top: 0;
    font-size: 13pt;
    font-weight: bold;
  }

  form {
    @media (min-width: @screen-sm-min) {
      width: 400px;
      margin: 0 auto;
    }
  }

  .field {
    clear: both;

    input {
      float: right;
      width: 60%;
    }
  }

  button#btn-guest-checkout {
    font-weight: bold;
    font-size: 10pt;
  }
}

// on desktop - show the save password panel and the newsletter signup panel side by side

@media (min-width: @screen-sm-min) {
  .panel-left {
    &.col-sm-6 {
      border-right: 1px solid #b3b3b3;
      padding-right: 30px;
      padding-left: 20px;
    }
  }

  .panel-right {
    &.col-sm-6 {
      padding-right: 20px;
      padding-left: 30px;
    }
  }
}

@media (min-width: @screen-md-min) {
  .panel-left {
    &.col-sm-6 {
      padding-right: 50px;
      padding-left: 50px;
    }
  }

  .panel-right {
    &.col-sm-6 {
      padding-right: 50px;
      padding-left: 50px;
    }
  }
}

.checkout-paypal-badge {
  text-align: center;
  margin-bottom: 20px;

  @media (min-width: @screen-md-min) {
    margin-bottom: 0;
  }

  img {
    display: inline-block;
    height: auto;
    max-width: 80%;
  }
}

.field-associate-club {
  position: relative;

  #livesearchresults:empty {
    display: none !important;
  }

  #livesearchresults {
    position: absolute;
    right: -1px !important;
    border: 1px solid #ddd;
    min-height: 100px;
    background: #fff;
    z-index: 11;
    left: 40% !important;
    top: 100% !important;
    width: 60% !important;
    display: block;
    max-height: 200px;
    overflow: auto;

    .footer {
      display: none;
    }

    #livesearchtable {
      .empty-text {
        padding: 10px 15px;
        font-weight: normal;
        font-size: 10pt;
        text-align: left;
        color: #666;
      }

      .header {
        background-color: #f2f2f2;
        padding: 7px 20px 7px 0;
        text-align: center;
        min-height: 34px;
        font-size: 11pt;
        font-weight: bold;

        .btn-close {
          font-size: 24px;
          position: absolute;
          top: 5px;
          right: 5px;
          height: 24px;

          button {
            border-radius: 20px;
            border: 1px solid #ccc;
            background: url(../../responsive-images/close-icon.svg) no-repeat 0
              0;
            background-size: 13px 13px;
            background-position: 50% 50%;
            background-color: white;
            width: 25px;
            height: 25px;
          }
        }
      }

      .taglink {
        padding: 10px;
        text-align: left;

        a {
          width: 100%;
          background: #f2f2f2;
          padding: 6px 8px;
          color: #333;
          font-size: 8pt;
          line-height: 8pt;
          text-decoration: none;
          margin: 0 3px 6px 0;
          display: inline-block;

          &:hover {
            background: @lovell-blue;
            color: white;
          }

          img {
            float: right;
            width: 5px;

            &.white {
              display: none;
            }
          }

          &:hover {
            img.white {
              display: block;
            }

            img.black {
              display: none;
            }
          }
        }
      }

      .scroll-bar {
        margin-right: 10px;
      }

      // media list shows list of items with left aligned thumbnail image
      .media-list {
        // show scrollbar if needed
        overflow: hidden;
        max-height: 260px;
        padding-left: 10px;
        padding-right: 45px;

        .media {
          height: 65px;
          border-bottom: 1px solid #ccc;
          padding: 5px 0px;
          padding-bottom: 0px;
          margin: 0;

          .media-body {
            height: 100%;
            padding: 5px;

            .price {
              font-weight: bold;
              font-size: 9pt;
            }

            .offer-price {
              font-weight: bold;
              font-size: 9pt;
              color: red;
            }

            .original-price {
              font-weight: bold;
              font-size: 7pt;
              text-decoration: line-through;
              margin-right: 3px;
            }

            a {
              display: block;
              color: #333;

              &.product-title {
                .main-font-bold();
                font-weight: 300;
                font-size: 9pt;
                line-height: 10pt;

                .livesearch-colour {
                  font-size: 7pt;
                  line-height: 10pt;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Stripe payment form*/
.cc-number,
.cc-exp,
.cc-cvc {
  background-origin: initial;
}

.has-error input {
  border-width: 2px;
}

.validation.text-danger:after {
  content: '';
}

.validation.text-success:after {
  content: '';
}

.payment-errors {
  background: transparent;
  color: red;
}

.StripeElement {
  background-color: #fff;
  height: 40px;
  padding: 10px 12px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #666;

  &.invalid {
    background: rgba(255, 0, 0, 0.8);

    &.focus {
      background: #fff;
    }
  }
}

.input-group-addon {
  .pf {
    font-size: 2em;
  }
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
