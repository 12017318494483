.button, .lovell-button {
  .main-font-bold();
  background-color: #111;
  font-size: 14px;
  padding: 12px 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: 1px solid #111;
  width: auto;
  text-align: center;
  text-decoration: none;
  border-radius: 2px;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: white;
  }

  img {
    width: 8px;
    height: 14px;
    margin-left: 3px;
  }

  /**
  *
  * modifiers
  *
  **/
  &--with-arrow {
    &:after {
      content: '';
      background: @icon-arrow-white;
      width: 10px;
      display: block;
      height: 16px;
      background-repeat: no-repeat;
      background-size: contain;
      margin-left: 10px;
    }
  }

  &--black {
    background: #000;
  }

  &--no-margin {
    margin-bottom: 0;
  }

  &--mercurial-green {
    background: @mercurial-green;
    color: @text-main;

    &.button--with-arrow {
      &:after {
        background-image: @icon-arrow-black;
      }
    }
  }

  &--secondary {
    background: transparent;
    border: 1px solid #111;
    color: #111 !important;

    &:hover {
      color: #111 !important;
    }
  }

  &--full-width {
    display: flex;
    width: 100%;
  }
}

.button-wrapper {
  display: flex;
  flex-direction: column;

  @media(min-width: @screen-sm-min) {
    flex-direction: row;
  }

  &--centred {
    justify-content: center;
  }
}