@import 'bootstrap/variables.less';
@import 'lovell-variables.less';

.currencyFlags() {
  //currency flags
  &:before {
    margin-right: 10px;
    display: block;
    height: 22px;
    width: 22px;
    background-size: 22px 22px;
    content: '';
  }

  &.uk {
    &:before {
      background-image: url('../../images/flag-icons/uk.png');
    }
  }

  &.eu {
    &:before {
      background-image: url('../../images/flag-icons/eu.png');
    }
  }

  &.au {
    &:before {
      background-image: url('../../images/flag-icons/au.png');
    }
  }

  &.nz {
    &:before {
      background-image: url('../../images/flag-icons/nz.png');
    }
  }

  &.ar {
    &:before {
      background-image: url('../../images/flag-icons/ar.png');
    }
  }

  &.us {
    &:before {
      background-image: url('../../images/flag-icons/us.png');
    }
  }

  &.jp {
    &:before {
      background-image: url('../../images/flag-icons/jp.png');
    }
  }

  &.fr {
    &:before {
      background-image: url('../../images/flag-icons/fr.png');
    }
  }

  &.es {
    &:before {
      background-image: url('../../images/flag-icons/es.png');
    }
  }
  //End currency flags
}

svg.feather {
  color: #333;
  width: 22px;
  height: 22px;
}





.basketConfirmation a {
  font-size: 14px;
}

button[disabled],
button:disabled,
.lovell-button[disabled] {
  background-color: #ccc;
}

.basket-voucher .basket-voucher-content input.button,
.lovell-checkbox input:checked+span:before,
.lovell-button {
  background-color: #111;
  font-size: 14px;
}

.basket-po-reference .basket-footer-item-content input.button,
.lovell-checkbox input:checked+span:before,
.lovell-button {
  background-color: #111;
  font-size: 14px;
}

.lovell-checkbox input:checked+span:before {
  border-color: #111;
  font-size: 24px;
}

input,
.button {
  .main-font();
}

/**************************************************************************************/
/* BREADCRUMB */
/**************************************************************************************/

.breadcrumb-container {
  display: none;

  @media (min-width: @screen-md-min) {
    display: block;
    width: 100%;
    margin: 0 auto;

    p.breadcrumb {
      font-size: 12px;
      .main-font-bold();
      display: flex;
      align-items: center;
      margin-top: 20px;

      a {
        .main-font();
      }

      .feather {
        width: 12px;
        height: 12px;
        margin: 0 6px;
      }
    }
  }
}

/**************************************************************************************/
/* HOME SELLING POINTS */
/**************************************************************************************/

.home-selling-points {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  height: 90px;

  @media (min-width: @screen-sm-min) {
    height: 70px;
  }

  @media (min-width: @screen-md-min) {
    height: 130px;
  }

  .home-selling-point {
    padding: 30px 0;
    font-size: 11px;
    letter-spacing: 1px;
    text-align: center;
    display: none;
    align-items: center;
    justify-content: center;
    &:last-child {
      display: flex;
    }

    @media (min-width: @screen-md-min) {
      display: flex;
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    img {
      margin-right: 10px;
      width: 30px;
      height: auto;
      display: block;
      @media (min-width: @screen-md-min) {
        width: 50px;
      }
    }
    span {
      display: block;
      text-align: left;
    }
  }
}

/**************************************************************************************/
/* HOME - LATEST PRODUCTS */
/**************************************************************************************/
.home-latest-products {
  padding: 40px 0;

  @media (min-width: @screen-sm-min) {
    padding: 40px 0 70px;
  }
}



/**************************************************************************************/
/* HOME GRID */
/**************************************************************************************/

.home-grid-panel {
  margin: 0;

  .grid-panel__item {

    &--no-margin {
      margin-bottom: 0;
    }

    @media (min-width: @screen-md-min) {
      a {
        display: block;
        position: relative;
      }

      .call-to-action {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        h2,
        h3 {
          margin: 0;
          margin-bottom: 10px;
          color: white;
        }

        .grid-panel__link {
          color: white;
          border: 1px solid white;
          padding: 8px 10px;
          border-radius: 2px;
        }
      }
    }

    @media (min-width: @screen-lg-min) {
      .call-to-action {
        h3 {
          font-size: 2.25rem;
        }
      }
    }
  }

  h2 {
    .main-font-bold();
    margin-bottom: 0px;
    font-size: 20px;
    color: #111;

    @media (min-width: @screen-md-min) {
      font-size: 26px;
    }
  }

  .grid-panel__link {
    background: none;
    color: #000;
    font-size: 14px;
    .main-font();
    padding: 8px 0;
    margin-top: 5px;
    display: inline;

    @media (min-width: @screen-sm-min) {
      font-size: 13px;
    }

    @media (min-width: @screen-md-min) {
      font-size: 18px;
      color: #999;
    }
  }

  &--1 {
    @media (min-width: @screen-md-min) {
      border-bottom: none;
    }

    .grid-panel__item {
      @media (min-width: @screen-md-min) {
        position: relative;

        h2 {
          position: absolute;
          color: #fff;
          left: 50%;
          transform: translateX(-50%);
          bottom: 130px;
        }

        .grid-panel__link {
          position: absolute;
          color: #fff;
          border: 1px solid #fff;
          left: 50%;
          transform: translateX(-50%);
          bottom: 60px;
          width: auto;
          padding: 10px 30px;
          font-size: 13px;
          .main-font();
        }
      }

      &:hover {
        @media (min-width: @screen-md-min) {
          .grid-panel__link {
            color: black;
            background-color: white;
          }
        }
      }
    }
  }

  &--2 {
    padding: 0;
    .grid-panel__item {
      margin: 0px;
      aspect-ratio: 13 / 10;

      @media (min-width: @screen-md-min) {
        margin: 0px;
      }

      a {
        position: relative;
        display: block;

        &:after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.3);
          background: radial-gradient(circle, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.3) 100%);
          transition: 0.3s opacity ease-out;
          opacity: 1;
        }

        &:hover {
          &:after {
            opacity: 0;
          }

          h3 {
            opacity: 0;
          }
        }
      }

      h3 {
        position: absolute;
        color: #fff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 15px;
        margin: 0;
        z-index: 1;
        white-space: nowrap;
        transition: 0.2s opacity ease-in;

        @media (min-width: @screen-sm-min) {
          font-size: 18px;
        }

        @media (min-width: @screen-lg-min) {
          font-size: 22px;
        }
      }

      &--unshaded {
        h3 {
          display: none;
        }

        a:after {
          content: none;
        }
      }
    }

    h3 {
      .main-font-bold();
      color: #333;
      font-size: 13px;
      margin-bottom: 0;
      margin-top: 8px;

      @media (min-width: @screen-sm-min) {
        font-size: 16px;
      }
    }
  }
}

.app-download-banner {
  aspect-ratio: 160 / 269;
  @media (min-width: @screen-sm-min) {
    aspect-ratio: 3200 / 919;
  }
}




/**************************************************************************************/
/* BASKET */
/**************************************************************************************/

body.login,
body.order {
  .recently-viewed {
    display: none;
  }
}

/**************************************************************************************/
/* SALE FILTER */
/**************************************************************************************/
.sale-filter-page {
  .breadcrumb-container {
    display: none;
  }

  #pageContent {
    @media (min-width: @screen-md-min) {
      margin-top: -10px;
    }
  }

  .chooser-header-panel {
    padding-bottom: 20px;
  }
}

body.sale-filter-page .breadcrumbs {
  display: none;
}

.sale-filter-wrapper {
  margin: 0 -10px;
  background: #c10925;
  @media (min-width: @screen-md-min) {
    margin: 0;
  }

  .sale-filter {
    display: grid;
    gap: 20px;
    padding: 20px;

    @media (min-width: 720px) {
      grid-template-columns: auto 1fr;
    }

    .sale-filter-pane:first-child {
      @media (min-width: 720px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 30px;
      }
    }

    .sale-filter-pane {
      text-align: center;

      

      .sale-filter-heading {
        margin: 0;
        color: white;
        display: flex;
        align-items: baseline;
        justify-content: center;
        max-width: 90%;
        margin: 0 auto;

        &__left {
          font-size: 3rem;
        }

        &__right {
          .main-font-bold();
          font-size: 8rem;
          line-height: 8rem;
        }
      }

      .sale-filter-description {
        margin: 0;
        color: white;
        .main-font();
        font-size: 2rem;
        letter-spacing: 1px;
      }

      .sale-filter-grid {
        display: none;

        @media (min-width: 720px) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 10px;
        }
        @media (min-width: 920px) {
          grid-template-columns: 1fr 1fr 1fr;
        }
        @media (min-width: @screen-lg-min) {
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        .sale-filter-link {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #ae0822;
          box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
          padding: 10px 12px;
          font-size: 1.1rem;
          text-decoration: none;
          text-align: center;
          color: #fff;
          background-color: #b70924;

          &:hover {
            background-color: #fff;
            color: #c10925;
          }

          &--active {
            background-color: #fff;
            color: #c10925;
          }
        }
      }

      .sod_select {
        display: block;

        @media (min-width: 720px) {
          display: none;
        }
      }
    }
  }
}

/**************************************************************************************/
/* CHECKOUT */
/**************************************************************************************/

#minimal-header {
  .pwa-back-button {
    display: none;
  }

  .logo {
    float: none;
    width: 100%;
    margin: 10px auto;

    a {
      display: block;

      img {
        max-width: 200px;
        margin: 0 auto;
      }
    }
  }
}

.lovell-form {
  .field {
    &:not(.field--preferences) {
      flex-direction: column;
      align-items: flex-start;
    }

    margin-bottom: 25px;

    @media (min-width: @screen-sm-min) {
      &:not(.field--preferences) {
        flex-direction: row;
        align-items: center;
      }
    }

    .field-name {
      display: none;
      .main-font();
      margin-bottom: 5px;
      font-weight: normal;
      font-size: 14px;

      @media (min-width: @screen-sm-min) {
        flex: 0 0 25%;
        width: 25%;
      }
    }

    .sod_select {
      .sod_label {
        height: 48px;
        .main-font();
        font-size: 14px;
        padding: 0 15px;
        color: #999;
      }

      .sod_option {
        font-size: 14px;
      }

      &.focus {
        .sod_label {
          border-color: #b3b3b3;
        }
      }
      &.is-valid {
        .sod_label {
          color: #333;
        }
      }
    }

    .newselect,
    .newselect-searchable {
      &.optionselected ~ span.select2-container {
        .select2-selection {
          border-color: #111;
          box-shadow: none !important;
          padding: 10px 20px 10px 10px;
          .select2-search {
            .select2-search__field::placeholder {
              color: #999999;
            }
            label {
              top: 0;
              transform: translatey(-10px);
              font-size: 11px;
              padding: 0 5px;
              left: 10px;
              color: #333;
              background: #fff;
            }
          }
        }
      }

      &:not(.optionselected) ~ span.select2-container {
        .select2-selection {
          .select2-search {
            .select2-search__field::placeholder {
              color: white;
            }
          }
        }

        &:focus-within,
        &:focus {
          border-color: #111;
          box-shadow: none !important;
          .select2-selection {
            .select2-search {
              .select2-search__field::placeholder {
                color: #999999;
              }
              label {
                top: 0;
                transform: translatey(-10px);
                font-size: 11px;
                padding: 0 5px;
                left: 10px;
                color: #333;
                background: #fff;
              }
            }
          }
        }
      }
    }

    .field-control {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;

      .sod_select {
        label {
          top: 0;
          transform: translatey(-50%);
          font-size: 0.75em;
          padding: 0 5px;
          left: 10px;
          color: #333;
          background: #fff;
        }
      }

      &.is-valid {
        input {
          border-color: #333 !important;
        }
      }

      input {
        width: auto;
        height: 48px;
        order: 1;
        font-size: 14px;
        padding-right: 30px;
        transition: 0.2s;

        @media (min-width: @screen-sm-min) {
          height: 48px;
        }

        @media (min-width: @screen-md-min) {
          padding: 8px 30px 8px 15px;
        }

        &:focus {
          box-shadow: none !important;
          border-color: #111;
          & + label {
            top: 0;
            transform: translatey(-50%);
            font-size: 11px;
            padding: 0 5px;
            left: 10px;
            color: #333;
            background: #fff;
          }
        }

        &:not(:placeholder-shown) + label {
          top: 0;
          transform: translatey(-50%);
          font-size: 11px;
          padding: 0 5px;
          color: #333;
          background: #fff;
          left: 10px;
        }
      }

      .field-description {
        display: inline;
        order: 3;
        margin-top: 2px;
        position: absolute;
        top: 100%;
        font-size: 11px;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
      }

      .field-error {
        order: 2;
        position: absolute;
        top: 101%;
        font-size: 12px;

        & + input + label + .field-description {
          display: none;
        }
      }

      label {
        position: absolute;
        top: 50%;
        left: 15px;
        color: #999;
        transition: 0.25s;
        z-index: 1;
        transform: translatey(-50%);
        font-size: 13px;
      }

      &--textarea {
        label {
          top: 20px;
        }
        textarea {
          padding: 10px 15px;
          border: 1px solid #b3b3b3;
          resize: vertical;
          &:focus {
            box-shadow: none !important;
            border-color: #111;
            & + label {
              top: 0;
              transform: translatey(-50%);
              font-size: 11px;
              padding: 0 5px;
              left: 10px;
              color: #333;
              background: #fff;
            }
          }
          &:not(:placeholder-shown) {
            border-color: #111;
            & + label {
              top: 0;
              transform: translatey(-50%);
              font-size: 11px;
              padding: 0 5px;
              color: #333;
              background: #fff;
              left: 10px;
            }
          }
        }
      }
    }
  }
}

#account-details-form,
#preferences-form {
  .field--preferences-gender {
    .radio input[type='radio'] {
      position: absolute;
      display: none;
    }

    .radio input[type='radio'] + .radio-label:before {
      content: '';
      background: transparent;
      border: 2px solid #000;
      display: inline-block;
      width: 36px;
      height: 36px;
      position: relative;
      top: -0.2em;
      margin-right: 10px;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease;
    }

    .radio input[type='radio']:checked + .radio-label {
      font-weight: bold;
    }

    .radio input[type='radio']:checked + .radio-label:after {
      content: '';
      background: #000;
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      cursor: pointer;
      transition: all 250ms ease;
      left: 8px;
      top: 2px;
    }

    label {
      color: #000;
      height: 36px;
      position: relative;
    }
    .radio {
      margin-bottom: 10px;
    }
    @media (min-width: 1080px) {
      .radios {
        flex-wrap: nowrap;
        display: flex;
      }
      .radios .radio {
        margin-right: 20px;
      }
    }
  }
}

body.checkout-confirm {
  #checkout-side-basket {
    @media (min-width: @screen-md-min) {
      display: block !important;
    }
  }
}

.checkout-panel {
  background: #fff;
}

.checkout-complete-title {
  display: none;
  margin-top: 0;
}

.checkout-complete {
  background: #fff;
  padding: 30px;
  max-width: 600px;
  margin: 0 auto;

  #membership {
    .container {
      width: 100%;
    }
  }
}

.checkout-share-title {
  padding: 0 20%;
}

.checkout-share-buttons {
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    background: #000;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    cursor: pointer;

    .fa {
      color: #fff;
      font-size: 20px;
      margin: 0;
    }
    img {
      height: 20px;
      width: 20px;
      display: 20px;
    }
  }
}

.guest-save-password {
  &-title {
    padding: 0 20%;
  }

  .tick-list {
    display: flex;
    flex-direction: column;
    align-items: center;

    li {
      margin-bottom: 10px;
      padding-left: 0;

      &:before {
        background-image: url(../../responsive-images/check-circle-black.svg);
      }
    }
  }

  .form-title {
    .main-font-bold();
    border-bottom: 1px solid #333;
    margin: 0 20px 20px;
    padding-bottom: 10px;

    @media (min-width: @screen-sm-min) {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  form {
    padding: 0 20px;

    .field {
      flex-direction: column;
      align-items: flex-start;

      @media (min-width: @screen-sm-min) {
        flex-direction: row;
        align-items: center;
      }

      .field-name {
        .main-font();
        font-weight: normal;
        margin-bottom: 5px;
      }
    }
  }
}

.field .sod_select {
  &:after {
    font-size: 20px;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    background-image: url(../../responsive-images/check-green.svg);
    background-size: contain;
    width: 18px;
    height: 18px;
    left: auto;
    right: 8px;
  }

  &.focus {
    .sod_label {
      box-shadow: none;
    }
  }

  &.is-valid {
    .sod_label {
      border-color: #333 !important;

      &:after {
        content: none;
      }
    }
  }
}

.field .field-control:after {
  font-size: 20px;
  top: 50%;
  transform: translateY(-50%);
  left: auto;
  right: 8px;
  content: '';
  background-image: url(../../responsive-images/check-green.svg);
  background-size: contain;
  width: 18px;
  height: 18px;
}

.minimal-footer {
  &__links {
    padding: 20px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (min-width: @screen-sm-min) {
      justify-content: center;
    }

    li {
      list-style: none;
      flex: 1 1 auto;
      border-right: 1px solid #ccc;

      @media (min-width: @screen-sm-min) {
        flex: 0 0 auto;
      }

      &:last-child {
        border-right: none;
        margin-right: 0;
      }

      a {
        text-decoration: none;
        color: #333;
        display: block;
        text-align: center;
        font-size: 15px;
        white-space: nowrap;

        @media (min-width: @screen-sm-min) {
          padding: 5px 25px;
        }
      }
    }
  }
}

.content-404 p {
  .main-font();

  a {
    color: #111;
  }
}


.field .sod_select .sod_label {
  font-size: 14px;
}

.message {
  font-weight: normal;
}


.lovell-form {
  position: relative;
}

.account-page {
  padding: 50px 0;
  @media (min-width: @screen-sm-min) {
    max-width: 600px;
    margin: 0 auto;
  }
  @media (min-width: @screen-md-min) {
    max-width: 720px;
  }
  &.is-closed {
    @media (min-width: @screen-md-min) {
      max-width: 900px;
    }
    .account-page__back {
      display: none;
    }
  }
  &:not(.is-closed) {
    .account-page__log-out {
      display: none;
    }
  }
  &__back {
    margin-top: 20px;
    display: inline-block;
    text-decoration: none;
    i {
      margin-right: 10px;
    }
  }
  .account-menu {
    h2 {
      font-size: 24px;
      text-align: left;
      margin-top: 0;
    }
  }
  form {
    .field {
      width: 100%;
    }
  }
  &__welcome-text {
    @media (min-width: @screen-md-min) {
      margin-bottom: 30px;
    }
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 18px;
    }
    div {
      font-size: 13px;
      line-height: 15px;
      @media (min-width: @screen-md-min) {
        max-width: 480px;
        margin: 0 auto;
      }
    }
  }
  &__form-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 0;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background: #bbb;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    @media (min-width: @screen-md-min) {
      top: -75px;
      display: none;
    }
    &:hover {
      background: #999;
    }
    svg {
      color: #fff;
      width: 18px;
      height: 18px;
    }
  }
  &__section-wrapper {
    @media (min-width: @screen-md-min) {
      margin: 0 -10px;
      width: auto;
    }
  }
  &__log-out {
    width: auto;
    max-width: 100%;
    margin: 0 auto;
    display: block;
    margin-top: 30px;
    background: #fff !important;
    color: #111 !important;
    border: 1px solid #333 !important;
    padding: 14px;
    @media (min-width: @screen-sm-min) {
      max-width: 320px;
    }
  }
  &__section {
    border-bottom-color: #ddd;
    &:first-child {
      border-top-color: #ddd;
      @media (min-width: @screen-md-min) {
        border-top: 1px solid #ddd;
      }
    }
    &.is-expanded {
      border-bottom: none;
      @media (min-width: @screen-md-min) {
        border-bottom: 1px solid #ddd;
      }
      .account-page__section-header {
        border-bottom: 1px solid @border-color;
        border-bottom: none;
        background: #111;
      }
      &:hover {
        @media (min-width: @screen-md-min) {
          box-shadow: none;
        }
      }
    }
    @media (min-width: @screen-md-min) {
      border: 1px solid #ddd;
      align-self: stretch;
      margin: 10px;
      flex: 0 0 calc(~'50% - 20px');
      padding: 0px;
    }
    &:after {
      @media (min-width: @screen-md-min) {
        width: 10px;
        height: 10px;
        right: 10px;
      }
    }
    &:hover {
      @media (min-width: @screen-md-min) {
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.07);
      }
    }
  }
  &__section-header {
    .menu-item-header {
      font-size: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      color: #333;
      @media (min-width: @screen-md-min) {
        padding: 0;
        margin-bottom: 10px;
        font-size: 18px;
      }
      img {
        display: none;
      }
      &:after {
        content: url(../../images/icons/chevron-forward-outline.svg);
        width: 14px;
        height: 14px;
        @media (min-width: @screen-md-min) {
          content: none;
        }
      }
    }
    .menu-item-description {
      font-size: 13px;
      color: #666;
    }
    @media (min-width: @screen-md-min) {
      padding: 20px;
      height: 100%;
    }
  }
  &__section-content {
    background: #f3f3f3;
    padding: 30px;
    @media (min-width: @screen-sm-min) {
      padding: 30px;
    }
    form {
      background: #fff;
      padding: 20px;
      margin: -20px;
      box-shadow: 0 0 6px 0px inset rgba(0, 0, 0, 0.1);
      border: 1px solid #ddd;
    }
    .header-bar {
      h2 {
        text-align: left;
        font-size: 24px;
        margin-top: 0;
        @media (min-width: @screen-md-min) {
          padding-bottom: 30px;
        }
      }
    }
    .display {
      &.account-details-display, &.password-display {
        border-bottom: 1px solid #ccc;
        padding-bottom: 20px;
        margin-bottom: 20px;
      }
      #add-delivery-address-new {
        margin-right: 0;
      }
      .display-content {
        flex: 1 1 auto;
      }
      .field {
        margin-bottom: 5px;
      }
    }

    .account-page__saved-addresses {
      .alt-address {
        margin-bottom: 0px;
        .address-ref {
          padding: 0;
          h3 {
            font-size: 16px;
            cursor: pointer;
          }
        }
        &__controls {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;
          margin-bottom: 20px;
          a {
            flex: 0 0 calc(~'50% - 5px');
            background: #fff;
            border: none;
            border: 1px solid #999;
            text-decoration: none;
            width: 100%;
            text-align: center !important;
            .delete {
              text-decoration: none;
              padding: 14px 30px;
              margin-top: 0;
              color: #111;
              font-size: 13px;
              .main-font();
            }
          }
          .edit {
            margin-top: 0;
            flex: 0 0 calc(~'50% - 5px');
          }
        }
        &.collapsed {
          .alt-address {
            &__address {
              display: none;
            }
            &__controls {
              display: none;
            }
          }
        }
      }
    }

    .back-to-home {
      border: 1px solid #ccc;
      transition: 0.3s all ease-out;
      position: absolute;
      top: -20px;
      right: -20px;
      background: #fff;
      margin: 0;
      font-size: 0;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);

      &:hover {
        background: #111;

        svg {
          color: #fff;
        }
      }

      svg {
        color: #999;
        transition: 0.3s all ease-out;
      }

      img {
        display: none !important;
      }
    }

    .back-to-display {
      color: #111;
      .main-font();
      font-size: 14px;
      align-items: center;
      img {
        display: none !important;
      }
      @media (min-width: @screen-sm-min) {
        display: inline-flex;
      }
      &:before {
        content: url(../../images/icons/chevron-back-outline.svg);
        width: 14px;
        margin-right: 10px;
      }
    }

    .find-address {
      .find-address-button {
        height: auto;
        padding: 15px;
        font-size: 14px;
        .main-font();
        letter-spacing: 0;
        background-color: #19a85e;
        margin-top: 0;
      }
    }
    h3 {
      text-align: left;
      font-size: 18px;
      margin-top: 0 !important;
    }

    .update {
      text-align: left;
      button {
        background: #19a85e;
        border-color: #19a85e;
        width: 100%;
        font-size: 13px;
        margin: 0;
        height: auto;
        padding: 14px;
        @media (min-width: @screen-sm-min) {
          width: 50%;
        }
      }
    }
    .edit {
      background: #fff;
      border: none;
      padding: 14px 30px;
      .main-font();
      border: 1px solid #999;
      font-size: 13px;
      color: #111;
      text-decoration: none;
      width: 100%;
      text-align: center !important;
      @media (min-width: @screen-sm-min) {
        min-width: 30%;
        width: auto;
        padding: 14px 20px;
        margin-top: 0;
      }
    }
    .section {
      &:first-child {
        border-top: none;
        margin-top: 0;
      }
    }
    &--history {
      .account-page__body {
        padding: 5px 0px;
        font-size: 14px;
        @media (min-width: @screen-md-min) {
          padding: 0;
        }
      }
    }
    #newsletter-form {
      .subscription-text {
        margin-top: 0;
      }
    }
  }
  &__history {
    background: #fff;
    box-shadow: 0 0 6px 0 inset rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    padding: 10px;
    margin: 20px -20px -20px;
    &-item--even {
      background: #f6f6f6;
    }
  }
}

body.vieworder {
  .order-display {
    &__page-title {
      font-size: 20px;
      padding-bottom: 20px;
      margin-bottom: 30px;
    }
    &__order-number-title {
      font-size: 14px;
    }
    &__order-details-title {
      margin-bottom: 5px;
    }
    &__status-body {
      margin-top: 10px;
      margin-bottom: 40px;
    }
    &__basket {
      .basketTable {
        .basket-item {
          .basket-image {
            align-self: flex-start;
          }
          .basket-data {
            .basket-data-inner {
              .basket-item-title {
                a {
                  font-size: 16px;
                }
              }
              .basket-colour {
                font-size: 12px;
                margin-top: 3px;
              }
              .basket-price-history {
                font-size: 16px;
              }
            }
          }
        }
      }
      .basket-summary-history {
        padding: 15px 0;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        .basket-footer-item {
          font-size: 16px;
        }
      }
    }
    &__footer-item {
      h2 {
        font-size: 16px;
      }
    }
    .cardTypeImage {
      max-width: 50px;
      height: auto;
      margin-right: 20px;
    }
    &__back-link {
      margin: 0;
      a {
        font-size: 14px;
        margin: 40px 0;
        display: inline-flex;
        align-items: center;
        .main-font();
        color: #111 !important;
        img {
          display: none !important;
        }
      }
    }
  }
}

#prsnl-confirm-panel {
  .modal-dialog {
    .modal-content {
      .modal-header {
        background: #111;
        color: #fff;
        h3 {
          margin-top: 0;
          font-size: 16px;
          text-transform: uppercase;
        }
      }
      .modal-body {
        padding: 20px;
        padding-bottom: 0;
        @media (min-width: @screen-sm-min) {
          padding: 40px;
        }
        h4 {
          margin-bottom: 20px !important;
          text-align: center;
        }
        .prsnl-confirm-panel__options {
          .confirm-row {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            @media (min-width: @screen-sm-min) {
              flex-direction: row;
            }
            .confirm-field {
              max-width: none;
              min-width: 0;
              flex: 0 0 100%;
              width: 65%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 12px;
              margin-bottom: 10px;
              background: #f3f3f3;
              border: 1px solid #ccc;
              color: #666;
              text-align: right;
              font-size: 14px;
              .small {
                text-align: left;
                color: #111;
                font-size: 11px;
                .main-font();
                text-transform: capitalize;
                letter-spacing: 0;
              }
              @media (min-width: @screen-sm-min) {
                flex: 0 0 calc(~'33.333% - 10px');
                flex-direction: column;
                width: calc(~'33.333% - 10px');
                margin: 0 10px 20px;
              }
            }
          }
        }
        .confirm-row {
          width: 100%;
          .confirm-field {
            width: 33.333%;
            margin: 0 auto;
            background: #f3f3f3;
            border: 1px solid #ccc;
            color: #666;
            padding: 12px;
            font-size: 14px;
            min-height: 56px;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (min-width: @screen-sm-min) {
              flex: 0 0 calc(~'33.333% - 10px');
              width: calc(~'33.333% - 10px');
            }
          }
        }
        ul {
          padding: 0;
          margin-top: 40px;
          li {
            font-size: 14px;
          }
        }
      }
      .modal-footer {
        padding: 20px;
        padding-top: 0;
        @media (min-width: @screen-sm-min) {
          padding: 40px;
          padding-top: 0;
        }
        .modal-btn {
          max-width: 300px;
          margin: 0 auto;
          border-radius: 3px;
          font-size: 14px;
          &.confirm-prsnl {
            background-color: #19a85e !important;
            border: 1px solid #19a85e;
            margin-top: 30px;
            margin-bottom: 15px;
          }
          img {
            display: none !important;
          }
        }
      }
    }
  }
}

.guznxf {
  display: none;
}
