// colors
@lovell-gray:                   #585858;
@lovell-light-gray:             #f2f2f2;
@lovell-medium-gray:            #666666;
@lovell-dark-gray:              #333333;
@lovell-blue:                   #01aef0;
@lovell-blue-light:             lighten(@lovell-blue, 10%);
@lovell-green:                  #19a85e;
@lovell-submit-green:           #19a85e;
@lovell-light-green:            #19a85e;
@lovell-green-light:            #19a85e;
@lovell-yellow:                 #ffa501;
@lovell-purple:                 #e230d2;
@lovell-orange:                 #e86107;
@lovell-orange-light:           lighten(@lovell-orange, 10%);
@lovell-warning:                @lovell-orange;
@lovell-border-color:           #ddd;
@lovell-border-color-light:     #dddddd;
@lovell-coral:                  #ff2657;
@lovell-red:                    #bf0606;
@lovell-red-light:              lighten(@lovell-red, 10%);
@mercurial-green:               #89ff00;
@mercurial-gray:                #e8e8e8;
@mercurial-dark-gray:           #2f2f2f;
@canterbury-red:                #F50006;
@motion-blur-yellow:            #E7FD39;
@motion-blur-pink:              #ED008C;
@motion-blur-green:             #27E976;
@motion-blur-blue:              #00ADEF;
@text-main:                     #111;
@sale-red:                      #ea1b1b;
@link-color:                    @lovell-blue;
@active-highlight:              #F4FCFE;
@border-color: #cccccc;
@ls-green: #19a85e;
@menu-offset: 40px;
@menu-offset-sm: 50vw;

@starHoverColour: grey;
@starColour: #000000;

// icons
@icon-arrow-black: url('../bem/blocks/button/right-arrow-icon-black.svg');
@icon-arrow-white: url('../bem/blocks/button/right-arrow-icon-white.svg');
@slider-arrow: url('../../responsive-images/right-arrow-icon.svg');


// fonts
@font-size-base:          1.4rem;
@font-size-extra-large:   @font-size-base * 1.50; // ~18px
@font-size-large:         @font-size-base * 1.25; // ~18px
@font-size-extra-small:   @font-size-base * 0.65; // ~12px
@font-size-h1:            @font-size-base * 2.6; // ~36px
@font-size-h2:            @font-size-base * 2.15; // ~30px
@font-size-h3:            @font-size-base * 1.7; // ~24px
@font-size-h4:            @font-size-base * 1.25; // ~18px
@font-size-h5:            @font-size-base * 1.1;
@font-size-h6:            @font-size-base * 0.85; // ~12px
@font-size-small:         @font-size-base * 0.85; // ~12px


//navbar
@navbar-height:                    60px;
@navbar-margin-bottom:             0px;
@navbar-border-radius:             @border-radius-base;
@navbar-padding-horizontal:        floor((@grid-gutter-width / 2));
@navbar-padding-vertical:          ((@navbar-height - @line-height-computed) / 2);
