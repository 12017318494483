.order-display {
  @media(min-width: @screen-md-min) {
    padding: 20px 12%;
  }
  &__header {
    @media(min-width: @screen-md-min) {
      margin-left: 0;
      width: 100%;
    }
  }

  &__header-message {
    text-align: center;
  }
  &__page-title {
    border-bottom: 1px solid @lovell-border-color;
    padding-bottom: 5px;
  }
  &__order-number-title {
    @media(min-width: @screen-sm-min) {
      text-align: center;
    }
    font-size: @font-size-large;
  }
  &__footer {
    .flex-container(wrap, flex-start, space-between);
    border-bottom: 1px solid @lovell-border-color;
  }
  &__footer-item {
    .flex-item(0,0,100%, stretch);
    margin-bottom: 20px;
    h4 {
      margin: 0 0 10px;
    }
    &--vat-invoice-link,
    &--returns-form-link,
    &--address {
      @media(min-width: @screen-sm-min) {
        .flex-item(1,1,40%, stretch);
      }
      @media(min-width: @screen-md-min) {
        .flex-item(1,1,50%, stretch);
      }
    }
    &--payment {
      border-top: 1px solid @lovell-border-color;
      padding-top: 20px;
      @media(min-width: @screen-md-min) {
        .flex-item(1,1,auto, stretch);
        border-top: none;
        padding-top: 0px;
      }
    }
    &--gift-message {
      border-top: 1px solid @lovell-border-color;
      padding-top: 20px;
      .flex-item(0,0,100%, stretch);
    }
    &--dispatch-date {
      h2 {
        margin: 0;
      }
    }
  }
  &__back-link {
    margin-top: 30px;
    margin-bottom: 40px;
    .main-font-bold();
    font-size: @font-size-large;
    @media(min-width: @screen-md-min) {
      padding: 0px 12% 20px;
    }
  }
}
