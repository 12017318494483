// ************************************************************************************************************
#footer-menu {
  // footer menu is hidden initially and then revealed once the 'is-loaded' class is added in js
  // this makes page load look a bit less staggered
  opacity: 0;
  height: 0;
  &.is-loaded {
    opacity: 1;
    height: auto;
  }
  ul {
    color: black;
    padding: 0;
    margin: 0;
    border-top: 1px solid #ccc;
    li {
      margin: 0;
      padding: 0;
      border-bottom: 1px solid #ddd;
      list-style: none;
      font-size: 0;
      &.phone {
        font-size: 16px;
        padding: 28px 30px;
        text-decoration: none !important;
        span:first-child {
          margin-right: 10px;
        }
      }
      div.menu-header {
        text-decoration: none;
        font-size: @font-size-large;
        text-align: left;
        padding: 20px;
        line-height: 2;
        display: block;
        cursor: pointer;

        .flex-container(nowrap, center, flex-start);
        .header {
          .flex-item(1,1,auto);
        }
      }
      div.expanded {
        background: @lovell-light-gray;
      }
      @media (min-width: @screen-md-min)  {
        div.expanded {
          background: white;
        }
      }
      .toggle {
        float: right;
        img.plus {
          width: 32px;
          height: 32px;
        }
        img.minus {
          display: none;
          width: 32px;
          height: 32px;
        }
      }
      ul.footer-sub-menu {
        display: none;
        a {
          color: black;
          padding: 28px 30px 28px 25px;
          font-size: 16px;
          width: 100%;
          .flex-container(nowrap,center,space-between);
          text-decoration: none;
          @media (min-width: @screen-sm-min)  {
            padding-left: 45px;
          }
          span.text {
            display: inline-block;
            vertical-align: middle;
          }
          img {
            display: inline-block;
            vertical-align: middle;
            float: right;
            width: 8px;
            height: 14px;
          }
        }
        .phone-hours {
          padding-left: 10px;
          font-size: 12px;
        }
      }
    }
  }
  @media (min-width: @screen-md-min)  {
    margin: 50px 0 80px;
    ul.top-menu {
      font-size: 0;
      border: none;
      > li {
        display: inline-block;
        vertical-align: top;
        border: none;
        div.menu-header {
          cursor: default;
          .header {
            margin-bottom: 10px;
            white-space: nowrap;
          }
        }
        .toggle {
          display: none;
        }
        ul.footer-sub-menu {
          display: block !important;
          border: none;
          li {
            display: block;
            border: none;
            a {
              padding: 0;
              background: none;
              ion-icon {
                display: none;
              }
            }
          }
        }
      }
      // customise the widths of the footer menu columns so that the last column lines up with the right side of the page
      > li:nth-child(2) {
        div.menu-header {
          margin-left: 8%;
          .header {
            width: 80%;
          }
        }
        .footer-sub-menu {
          margin-left: 8%;
        }
      }
      > li:nth-child(3) {
        div.menu-header {
          margin-left: 15%;
          .header {
            width: 85%;
          }
        }
        .footer-sub-menu {
          margin-left: 15%;
        }
      }
      > li:nth-child(4) {
        div.menu-header {
          margin-left: 20%;
          .header {
            width: 100%;
          }
        }
        .footer-sub-menu {
          margin-left: 20%;
        }
      }
    }
  }
  @media (min-width: @screen-lg-min)  {
    ul.top-menu {
      padding: 0 10%;
    }
  }
}

footer {
  background: #000;
  padding: 50px 10px 80px;
  color: #fff;

  @media (min-width: @screen-md-min) {
    padding: 80px 0;
  }

  .footer-main {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: @screen-md-min) {
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    .container {
      width: auto;
      padding: 0;
      overflow: visible;

      .row {
        padding: 0;
        margin: 0;

        .col-xs-12 {
          padding: 0;
        }
      }
    }

    #membership {
      margin-bottom: 40px;

      @media (min-width: @screen-sm-min) {
        padding: 0 20%;
        margin-bottom: 20px;
      }

      @media (min-width: @screen-md-min) {
        text-align: left;
        padding: 0;
      }

      h3 {
        margin-top: 0;
        margin-bottom: 30px;
        .main-font-bold();

        @media (min-width: @screen-md-min) {
          font-size: 15px;
        }
      }

      #signup-form {
        > div {
          width: auto;
          margin: 0;
          float: none;
        }

        .form {
          display: flex;

          .inputs-wrapper {
            display: flex;
            width: 100%;
          }

          #email {
            background: transparent;
            color: #fff;
            width: inherit;
            flex: 1;

            @media (min-width: @screen-md-min) {
              font-size: 13px;
            }
          }

          #signup-submit {
            .main-font-bold();
            background: #fff;
            color: #000;
            text-transform: uppercase;
            font-size: 14px;
            padding: 0 20px;
            width: inherit;
            white-space: nowrap;
            flex: 0 0 20%;

            @media (min-width: @screen-md-min) {
              font-size: 13px;
            }
          }
        }
      }

      .terms {
        font-size: 12px;
        margin-top: 10px;
        a {
          color: #ccc;
        }
      }
    }

    #footer-menu {
      margin: 0;
      flex: 1;

      @media (min-width: @screen-md-min) {
        padding-right: 6%;
      }

      ul.top-menu {
        display: flex;
        flex-direction: column;
        border-top: 1px solid #444;

        @media (min-width: @screen-md-min) {
          flex-direction: row;
          gap: 40px;
          justify-content: flex-start;
          border-top: none;
          padding: 0;
        }

        > li {
          width: auto;
          border-bottom: 1px solid #444;

          @media (min-width: @screen-md-min) {
            background: transparent;
            color: #fff;
            border-bottom: none;
            flex: 1 1 auto;
          }

          .menu-header {
            margin-left: 0;
            color: #fff;
            padding: 13px 20px;

            @media (min-width: @screen-md-min) {
              margin-bottom: 15px;
              line-height: normal;
              padding: 0 0 10px;
            }

            .header {
              width: auto;
              height: auto;
              border-bottom: none;
              .main-font-bold();
              font-size: 16px;

              @media (min-width: @screen-md-min) {
                font-size: 18px;
                margin: 0;
              }
            }

            .toggle {
              ion-icon {
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .feather-minus {
                display: none;
                color: #999;
                width: 30px;
                height: 30px;
              }

              .feather-plus {
                color: #999;
                width: 30px;
                height: 30px;
              }
            }

            &.expanded {
              background: none;

              .toggle {
                .feather-minus {
                  display: block;
                }

                .feather-plus {
                  display: none;
                }
              }
            }
          }

          .footer-sub-menu {
            margin: 0;
            border-top: none;

            li {
              white-space: nowrap;
              border-bottom: none;

              @media (min-width: @screen-md-min) {
                margin-bottom: 10px;
              }

              &.phone {
                color: #fff;
              }

              &:last-child {
                margin-bottom: 20px;
              }

              a {
                color: #fff;
                padding: 13px 30px;

                @media (min-width: @screen-md-min) {
                  color: #8e8e8e;
                  font-size: 15px;
                  padding: 0;

                  &:hover {
                    color: #fff;
                  }
                }
              }
            }
            &.cols-4 {
              @media (min-width: @screen-md-min) {
                columns: 4;
              }
              li {
                @media (min-width: @screen-md-min) {
                  margin-right: 30px;
                }
              }
            }
            &.cols-2 {
              @media (min-width: @screen-md-min) {
                columns: 2;
              }
            }
          }
        }
      }
    }
  }

  .payment-icons {
    display: flex;
    width: 270px;
    flex-wrap: wrap;
    justify-content: center;
    margin: 50px auto 0;

    @media (min-width: @screen-sm-min) {
      width: 540px;
    }

    @media (min-width: @screen-md-min) {
      margin: 40px auto 0;
    }

    .payment-icon {
      margin: 5px;

      img {
        display: block;
        width: 80px;
        height: auto;
      }
    }
  }

  .company-info {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .copyright {
      .main-font-bold();
      margin-top: 40px;
    }
    a {
      color: #fff;
      padding: 10px;
      display: block;
      text-decoration: none;
      text-align: center;
    }
    .links {
      display: flex;
      flex-direction: column;
      @media (min-width: @screen-sm-min) {
        flex-direction: row;
      }
    }
  }

  .footer-icons {
    display: none;
  }
}

