.text-panel {
  text-align: center;
  padding: 50px 0;
  &__title {
    margin: 0 0 20px;
    padding: 0px;
    text-align: center;
    .main-font-bold();
    font-size: @font-size-extra-large;
  }
  &__body {
    margin: 0 auto 20px auto;
    padding: 0px;
    text-align: center;
    width: 280px;
    @media (min-width: @screen-sm-min) {
      font-size: @font-size-large;
    }
  }
  &__button {
    margin-bottom: 0;
    padding: 8px 36px;
  }
}
