.signup-form {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  input {
    align-self: stretch;
    min-width: 30%;
    padding: 10px;
    border: 1px solid #b3b3b3;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  button {
    background: #01aef0;
    color: #fff;
    border: none;
    display: inline-block;
    font-size: 11pt;
    line-height: 24px;
    padding: 5px 12px;
    align-self: stretch;
    min-width: 120px;
  }
}
#newsSignupFeedback {
  text-align: center;
  color: @lovell-blue;
}